<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  import { push } from "svelte-spa-router";

  import PropertyFeatures from "../../property-features-service";

  export let propertyId;
  export let logoProperty;
  export let propertyName;
  export let managementColors;
  export let filterSvg;
  const dispatch = createEventDispatcher();

  let auth = localStorage.getItem("auth")
    ? JSON.parse(localStorage.getItem("auth"))
    : false;
  const styleSettings = {
    "--dynamic-color": managementColors.color,
    "--filter-svg": managementColors.filter,
    "--colorLetter": managementColors.colorLetter,
    "--buttonCircle": managementColors.backgroundButtCircle,
    "--backColorAlert": managementColors.backColorAlert,
    "--backColorPromotions": managementColors.backColorPromotions,
    "--colorHoverIcon":
      managementColors.general.colorMode == "white" ? "#212121" : "#FFF",
    "--backColorIcon":
      managementColors.general.colorMode == "white" ? "#F4F4F4" : "#212121",
    "--colorButtonArea":
      managementColors.general.colorMode == "white" ? "#212121" : "#F4F4F4",
    "--colorIcon":
      managementColors.general.colorMode == "white" ? "#686C6D" : "#E3E3E3",
    "--colorTextArea":
      managementColors.general.colorMode == "white" ? "#686C6D" : "#FFF",
      "--colorBrdTop":
      managementColors.general.colorMode == "white" ? "#E3E3E3" : "#363636",
  };
  Object.keys(styleSettings).forEach((key) =>
    document.querySelector(":root").style.setProperty(key, styleSettings[key]),
  );
  let loading = true;

  onMount(() => {
    loading = true;
    propertyId = propertyId.replace(",", "");

    loading = false;
  });

  onDestroy(() => {
    dispatch("destroy");
  });

  const closeModal = () => {
    // Add logic to close the modal

    dispatch("finish");
  };


  const finishedSurvey = () => {
    window.location.href = auth.checkInInvitation;
  };

  const styling = (node, vars) => {
    Object.entries(vars).forEach(([p, v]) => {
      node.style[p] = v;
    });
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="modal-overlay" on:click={closeModal}>
  <div
    class="modal-content"
    on:click|stopPropagation
    use:styling={{
      background: managementColors.general.backgroundColorCards,
    }}
  >
    {#if loading}
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-blue">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    {:else}
      <div class="col s12 padd__modal">
        <div class="cont__modal">
          <div class="cont__modal__puntuar">
            <div class="cont__img">
              <img alt="img" src={logoProperty} class="img__outlet" />
            </div>
            <div class="content__information">
              <div class="text__title">{$_("Speed up your check in")}</div>
              <div class="text__sub">{$_("Continue with your Check-in remotely to register and access your benefits.")} </div>

              <div class="marg__top">
                <div>
                  <button
                  class="btn btn-success buttom__none"
                  on:click={closeModal}>{$_("Back")}</button
                >
                </div>
                <div>
                <button
                  class="btn btn-success buttom__footer"
                  use:styling={{
                    color: managementColors.general.colorAlternative,
                  }}
                  on:click={finishedSurvey}>{$_("Start your Check in")}</button
                >
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .padd__modal {
    padding: 0px !important;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 103%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background: white;
    border-radius: 20px;
    width: 296px;
    text-align: center;
  }
  .preloader-wrapper {
    margin: auto;
  }

  .cont__img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 126px;
    height: 126px;
    position: relative;
    top: -71px;
    border-radius: 8px;
  }

  .content__information {
    margin-top: -52px;
    text-align: center;
    padding: 0px 12px 32px 12px;
  }

  .buttom__footer {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    margin-top: 10px;
    text-transform: capitalize;
    background-color: var(--dynamic-color);
  }
  .buttom__footer:hover {
    background-color: var(--dynamic-color);
  }

  .buttom__none {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    margin-top: 10px;
    text-transform: capitalize;
    background-color: transparent;
    color: var(--colorIcon);
    -webkit-box-shadow: none !important; 
     box-shadow: none !important;
  }

  .buttom__none:hover {
    background-color: transparent;
  }
  .img__outlet {
    max-height: 100%;
    max-width: 100%;
    width: 126px;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 8px;
  }

  .marg__top {
    margin-top: 16px;
  }
  .text__title{
      font-size: 24px;
      font-weight: 700;
      color: var(--colorHoverIcon);
      margin-top: 16px;
    }

    .text__sub{
      font-size: 16px;
      font-weight: 400;
      color: var(--colorHoverIcon);
      margin-top: 16px;
    }

  @media only screen and (min-width: 0px) and (max-width: 736px) {
    .modal-content{
      position: fixed;
    bottom: 0px;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    }
    .modal-overlay{
      justify-content: none !important; 
     align-items: none !important;
    }

    .marg__top{
      border-top: 1px solid var(--colorBrdTop);
      padding: 8px 16px 0px 16px;
    }

    .cont__img{
      position: none;
      top: none;
      margin-top: 16px;
      width: 149px;
      position: unset;
    }

    .text__title{
      font-size: 20px;
      font-weight: 700;
      color: var(--colorHoverIcon);
      margin-top: 16px;
      padding: 0px 16px 0px 16px;
    }

    .text__sub{
      font-size: 14px;
      font-weight: 400;
      color: var(--colorHoverIcon);
      margin-top: 16px;
      padding: 0px 16px 0px 16px;
    }

    .img__outlet{
      width: 149px;
height: 149px;
position: unset;
    }

    .content__information{
      margin-top: 0px;
    }
    
  }


</style>
